export default {
  "Are you sure to generate code?": "Are you sure to generate code?",
  "Yes": "Yes",
  "No": "No",
  "SaveDraftSuccess": "Save draft success",
  "SaveGenerateSuccess": "Save generate success",
  "SaveAbolishedSuccess": "Save abolished success",
  "Loading": "Loading",
  "Point Please select categories": "Point Please select categories",
  "Save Point": "Save Point",
  "Point Name": "Point Name",
  "Point Categories": "Point Categories",
  "Point Location": "Point Location",
  "LAT": "LAT",
  "LONG": "LONG",
  "Point Description": "Point Description",
  "Point Action": "Point Action",
  "Required choose at least a category": "Required choose at least a category",
  "6 at most": "6 at most",
  "Are you sure to delete this code": "Are you sure to delete this code",
  "Spot": "Spot",
  "Please input point name in japanese": "Please input point name in japanese",
  "Please input point name in english": "Please input point name in english",
  "Please input point name in chinese": "Please input point name in chinese",
  "Please input point name in korean": "Please input point name in korean",
  "Please input your latitude": "Please input your latitude",
  "Please input your longitude": "Please input your longitude",
  "Import Type": "Import Type",
  "Import Code Label": "Import Code Label",
  "Import": "Import",
  "Enter a location": "Enter a location",
  "Successful": "Successful",
  "Compose Submit": "Compose Submit",
  "days": "days",
  "month": "month",
  "Please pick a date": "Please pick a date",
  "Please select repeat times": "Please select repeat times",
  "There is a problem with the uploaded file. This file will not be uploaded": "There is a problem with the uploaded file. This file will not be uploaded",
  "Company name placeholder": "Company name placeholder",
  "InvalidPhone": "InvalidPhone",
  "Confirmation code is wrong. Please try again.": "Confirmation code is wrong. Please try again.",
  "Project name": "Project name",
  "Project name max length 50": "Project name max length 50",
  "Save Project": "Save Project",
  "Created Project": "Created Project",
  "Project Compose": "Project Compose",
  "Change Project": "Change Project",
  "UpdateProjectSuccess": "UpdateProjectSuccess",
  "Would you like to duplicate this code？": "Would you like to duplicate this code？",
  "DuplicateSuccess": "DuplicateSuccess",
  "Code Compose": "Code Compose",
  "DeletedSuccess": "DeletedSuccess",
  "Switch": "Switch",
  "ViewCell": "ViewCell",
  "De-active": "De-active",
  "Profile": "Profile",
  "Menu Change Password": "Menu Change Password",
  "Email Settings": "Email Settings",
  "Company name Settings": "Company name Settings",
  "Contact phone Settings": "Contact phone Settings",
  "Company address Settings": "Company address Settings",
  "Company URL Settings": "Company URL Settings",
  "Descriptions Settings": "Descriptions Settings",
  "Please input your Company Address!": "Please input your Company Address!",
  "Please input your Company Name!": "Please input your Company Name!",
  "Please input Url format": "Please input Url format",
  "ChangeEmailSuccess": "ChangeEmailSuccess",
  "Please input your old password!": "Please input your old password!",
  "Please input confirm password": "Please input confirm password",
  "Confirm password does not match": "Confirm password does not match",
  "Total amount": "Total amount",
  "Invoice date": "Invoice date",
  "Paid date": "Paid date",
  "Unit price": "Unit price",
  "Quantity": "Quantity",
  "Voice code": "Voice code",
  "Navi code": "Navi code",
  "Mix code": "Mix code",
  "Spot code": "Spot code",
  "Sub total": "Sub total",
  "On Going": "On Going",
  "Paid": "Paid",
  "Unpaid": "Unpaid",
  "Id": "ID",

  "Please input your ENG": "Please input your ENG",
  "Please input your JPN": "Please input your JPN",
  "Please input your CHI": "Please input your CHI",
  "Please input your KOR": "Please input your KOR",
  "Please input your LAT": "Please input your LAT",
  "Please input your LONG": "Please input your LONG",
  "Please input exactly latitude or longitude.": "Please input exactly latitude or longitude.",
  "Select code to import": "Select code to import",
  "Click or drag file TYPES to this area to upload": "Click or drag file {types} to this area to upload",
  "File must smaller than 10MB": "File must smaller than 10MB",
  "No any code need to generate": "No any code need to generate",
  "Code name": "Code name",
  "Ready": "Ready",
  "Processing": "Processing",
  "These codes are generated": "These codes are generated",
  "Generate error": "Generate error",
  "Cannot generate code": "Cannot generate code",
  "Please input your project name": "Please input your project name",
  "This project is created": "This project is created",
  "Created date": "Created date",
  "View code list": "View code list",
  "Published": "Published",
  "Abolished": "Abolished",
  "Preview will display here after publishing": "Preview will display here after publishing",
  "Please input a valid phone number!": "Please input a valid phone number!",
  "Please input a valid E-mail!": "Please input a valid E-mail!",
  "Please input your Company_Address": "Please input your Company_Address",
  "Please input your Company_Name!": "Please input your Company_Name!",
  "Please input your Company_Url!": "Please input your Company_Url!",
  "Please input your Descriptions!": "Please input your Descriptions!",
  "Enter old password": "Enter old password",
  "Enter new password": "Enter new password",
  "Password length at least 8 characters": "Password length at least 8 characters",
  "Confirm new password": "Confirm new password",
  "Change password is succeed": "Change password is succeed",
  "Request error, please try again later": "Request error, please try again later",
  "Home": "Home",
  "Company": "Company",
  "Projects": "Projects",
  "Compose projects": "Compose projects",
  "Edit project": "Edit project",
  "Code list": "Code list",
  "Compose code": "Compose code",
  "Edit code": "Edit code",
  "Message": "Message",
  "Message list": "Message list",
  "Compose message": "Compose message",
  "Message detail": "Message detail",
  "Message Statistic": "Message Statistic",
  "Settings": "Settings",
  "Email Setting": "Email Setting",
  "Change Password": "Change Password",
  "All": "All",
  "Waiting": "Waiting",
  "Rejected": "Rejected",
  "Approved": "Approved",
  "Sending": "Sending",
  "Cancel": "Cancel",
  "Done": "Done",
  "Draft": "Draft",
  "Gender": "Gender",
  "Gender of user": "Gender of user",
  "Age": "Age",
  "Age of user": "Age of user",
  "Location": "Location",
  "Location to which the user belongs": "Location to which the user belongs",
  "Log out": "Log out",
  "Code data": "Code data",
  "Title": "Title",
  "Title code demo text": "Title code demo text",
  "Manual": "Manual",
  "Import File": "Import File",
  "Code size": "Code size",
  "EC error level": "EC error level",
  "Add Code from Mobile": "Add Code from Mobile",
  "Add Code": "Add Code",
  "Save": "Save",
  "Generate": "Generate",
  "Abolish": "Abolish",
  "Publish": "Publish",
  "Add Point": "Add Point",
  "Edit": "Edit",
  "Remove": "Remove",
  "Your location": "Your location",
  "Type": "Type",
  "Navi": "Navi",
  "Faci": "Faci",
  "Code Name": "Code Name",
  "Enter code name in english": "Enter code name in english",
  "ENG": "ENG",
  "Enter code name in japanese": "Enter code name in japanese",
  "JPN": "JPN",
  "Enter code name in chinese": "Enter code name in chinese",
  "CHI": "CHI",
  "Enter code name in korean": "Enter code name in korean",
  "KOR": "KOR",
  "Preview": "Preview",
  "Uni-Voice": "Uni-Voice",
  "Title is required": "Title is required",
  "Title max 65 length": "Title max 65 length",
  "Content": "Content",
  "Content is required": "Content is required",
  "Title max 240 length": "Title max 240 length",
  "siteName": "siteName",
  "Univoice": "Uni-Voice",
  "UniVoice": "Uni-Voice",
  "Total": "Total",
  "items": "items",
  "No data": "No data",
  "Network error": "Network error",
  "Authorization verification failed": "Authorization verification failed",
  "Server error, please try again later.": "Server error, please try again later.",
  "Rejected reason": "Rejected reason",
  "Message content": "Message content",
  "Next": "Next",
  "Targeting": "Targeting",
  "A valid targeting condition is required": "A valid targeting condition is required",
  "Scheduling": "Scheduling",
  "Save as draft": "Save as draft",
  "Submit": "Submit",
  "Option": "Option",
  "Error": "Error",
  "Success": "Success",
  "Push has been saved.": "Push has been saved.",
  "Status": "Status",
  "Search": "Search",
  "Reset": "Reset",
  "New Compose": "New Compose",
  "Target": "Target",
  "Created": "Created",
  "Updated": "Updated",
  "Actions": "Actions",
  "Message statistic": "Message statistic",
  "Gender distribution": "Gender distribution",
  "No data available.": "No data available.",
  "City distribution": "City distribution",
  "City": "City",
  "Count": "Count",
  "Age distribution": "Age distribution",
  "Progression by time": "Progression by time",
  "Attachment": "Attachment",
  "Image": "Image",
  "PDF Attachment": "PDF Attachment",
  "URL is not valid": "URL is not valid",
  "Add languages": "Add languages",
  "Select": "Select",
  "Target Users": "Target Users",
  "Add Filter": "Add Filter",
  "All users": "All users",
  "Starting time": "Starting time",
  "format.scheduling', { minSending: minSending().format('YYYY-MM-DD HH:mm": "format.scheduling', { minSending: minSending().format('YYYY-MM-DD HH:mm",
  "Select Time": "Select Time",
  "Repeated Times": "Repeated Times",
  "Duration (hours):": "Duration (hours):",
  "Please select duration": "Please select duration",
  "Upload": "Upload",
  "Image must smaller than 10MB!": "Image must smaller than 10MB!",
  "and": "and",
  "Please select": "Please select",
  "Male": "Male",
  "Female": "Female",
  "Others": "Others",
  "Start": "Start",
  "Repeatedly Times": "Repeatedly Times",
  "Duration": "Duration",
  "hours": "hours",
  "View statistics": "View statistics",
  "Company System": "Company System",
  "Account information": "Account information",
  "Email": "Email",
  "Please input your E-mail!": "Please input your E-mail!",
  "The input is not valid E-mail!": "The input is not valid E-mail!",
  "Enter your email": "Enter your email",
  "Contact email": "Contact email",
  "Contact phone": "Contact phone",
  "Please enter a phone number": "Please enter a phone number",
  "Company name": "Company name",
  "Please enter company name": "Please enter company name",
  "Company address": "Company address",
  "Please enter company address": "Please enter company address",
  "Your registration has been received.": "Your registration has been received.",
  "We will approve and send your account password to email ": "We will approve and send your account password to email ",
  "Go to Sign in": "Go to Sign in",
  "The token is expired or does not exist": "The token is expired or does not exist",
  "Registration": "Registration",
  "Already have account?": "Already have account?",
  "Sign in": "Sign in",
  "Register": "Register",
  "Please check your email.": "Please check your email.",
  "We have sent registation form to your entered email": "We have sent registation form to your entered email",
  "Email is already exists. Please use other email.": "Email is already exists. Please use other email.",
  "Reset your password": "Reset your password",
  "Please input verification code": "Please input verification code",
  "Reset code": "Reset code",
  "Resend": "Resend",
  "At least 8-digit password, case sensitive.": "At least 8-digit password, case sensitive.",
  "New Password": "New Password",
  "Send code": "Send code",
  "Sign In": "Sign In",
  "Change password has been successful": "Change password has been successful",
  "Reset code is being sent...": "Reset code is being sent...",
  "Notice": "Notice",
  "Please update your password": "Please update your password",
  "Please enter your password": "Please enter your password",
  "Enter your password": "Enter your password",
  "Please enter confirm password": "Please enter confirm password",
  "Confirm password": "Confirm password",
  "Two passwords are inconsistent": "Two passwords are inconsistent",
  "Don’t have account?": "Don’t have account?",
  "Forget your password?": "Forget your password?",
  "Reset password?": "Reset password?",
  "Login failed": "Login failed",
  "Compose project": "Compose project",
  "Created project is success": "Created project is success",
  "Compose": "Compose",
  "Name": "Name",
  "Active": "Active",
  "Edit Code": "Edit Code",
  "Project": "Project",
  "Duplicate": "Duplicate",
  "Code Preview": "Code Preview",
  "Are you sure to duplicate this code?": "Are you sure to duplicate this code?",
  "ID": "ID",
  "Code type": "Code type",
  "Create date": "Create date",
  "Updated date": "Updated date",
  "Download": "Download",
  "Switch Project": "Switch Project",
  "Uni-Voice code": "Uni-Voice code",
  "View": "View",
  "Delete": "Delete",
  "Change password": "Change password",
  "Update": "Update",
  "Pick a location": "Pick a location"
};